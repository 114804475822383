<style scoped lang="scss">
.companyChoose-box {

  /*height: 100%;*/
  /*box-sizing: border-box;*/
  .companyChoose-box-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    .box-title-icon {
      background: url('~@/static/projImg/comp-icon-act.svg') center center no-repeat;
      width: 45px;
      height: 45px;
      display: inline-block;
      /*background-color: #e6f0fe;*/
      /*border-radius: 50%;*/
      /*margin-bottom: 10px;*/
    }

    .box-title-text {
      color: #4e7ab6;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .companyChoose-box-content {
    margin: 10px 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /*height: calc(100% - 90px);*/
    overflow-y: auto;

    .box-content-item {
      width: 320px;
      height: 175px;
      box-sizing: border-box;
      background-color: #f9fcff;
      border-radius: 10px;
      border: 1px solid #eff6ff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 20px;

      .box-content-item-icon {
        background: url('~@/static/projImg/comp-icon.svg') center center no-repeat;
        width: 45px;
        height: 45px;
        display: inline-block;
        background-color: #e6f0fe;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      .box-content-item-name {
        color: #4a4a4a;
        font-size: 14px;
        font-weight: bold;
      }

      &:hover {
        border: 1px solid #5a9cf8;

        .box-content-item-icon {
          background: url('~@/static/projImg/comp-icon-act.svg') center center no-repeat;
        }

        .box-content-item-name {
          color: #5a9cf8;
        }
      }
    }
  }
}

.ssss {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: calc(100% - 90px);
  overflow-y: auto;
}
</style>
<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="companyChoose-box">
      <el-dialog
        :visible="showDialog"
        @close="close"
        width="80%"
        v-if="showCompChoose"
      >
        <div class="companyChoose-box">
          <div class="companyChoose-box-title">
            <div class="box-title-icon"></div>
            <!-- 请选择登陆企业 -->
            <div class="box-title-text">
              {{ $t('tips.selectCompanyLogin') }}
            </div>
          </div>
          <div id="box-content" class="companyChoose-box-content">
            <li class="box-content-item" v-for="(table, index) in tableList" :key="index" @click="chooseOne(table)">
              <div class="box-content-item-icon"></div>
              <div class="box-content-item-name">
                {{ table.locationName | filterUndefinedNull }}
              </div>
            </li>
          </div>
        </div>
      </el-dialog>
    </div>

    <template v-if="agreementShow">
      <serviceGreementDialog :show="agreementShow" :layerLoading="layerLoading" :pdfUrl="pdfUrl" @close="closePdf"
        @sure="sureSign"></serviceGreementDialog>
    </template>

    <!-- 电子债权凭证服务协议 -->
    <!-- <template v-if="isContract">
      <contract :isDomestic="isDomestic"> </contract>
    </template> -->
  </div>
</template>
<script>
import serviceGreementDialog from '@/components/serviceGreementDialog/index.vue'
import $ from 'jquery'
// import contract from '../contract'
export default {
  props: {
    showCompChoose: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    serviceGreementDialog,
    // contract,
  },
  data() {
    return {
      contractTypeDz: null, // 电子债务凭证类型
      mediaId: 'ffe0dd96c3bdffaf40099bdcabbbde9e', // 企业id
      isDomestic: true, // 为true的时候是境内，false是境外
      wqsObj: {
        id: '1694959992440262658',
        contractNo: 'CFCA202308250000',
        contractName: 'CFCA数字证书服务协议',
        contractModule:
          '{"dictParam":"DIGIPO","displayName":"digipo","enDisplayName":"digipo","dictKey":"digipo"}',
        contractType:
          '{"dictParam":"DIGIPO_CFCA","displayName":"CFCA数字证书服务协议","enDisplayName":"digipo_cfca","dictKey":"digipo_cfca"}',
        templateId: '76',
        templateType:
          '{"dictParam":"DIGIPO_CFCA","displayName":"CFCA数字证书服务协议","enDisplayName":"DIGIPO_CFCA","dictKey":"DIGIPO_CFCA"}',
        refKey: '1694950461346095105',
        contractStatus:
          '{"dictParam":"WAIT_SIGN","displayName":"待签署","enDisplayName":"Pending for signing","dictKey":"WAIT_SIGN"}',
        accessPath: 'nullnull',
        mediaId: 'ffe0dd96c3bdffaf40099bdcabbbde9e',
        catgId: 'CCONTRACT013',
        custId: '1694950461346095105',
        custName: '上海浦东发展银行股份有限公司-jenson',
        custRole:
          '{"dictParam":"FUND","displayName":"资金方","enDisplayName":"Fund","dictKey":"fund"}',
        custCreateWay:
          '{"dictParam":"INNER","displayName":"内部建档","enDisplayName":"Register by invitation","dictKey":"inner"}',
        custLocation:
          '{"dictParam":"TERRITORY","displayName":"境内","enDisplayName":"Territory","dictKey":"territory"}',
        custRegisteredArea: '中国',
        offlineFlag: 'Y',
        enable: 'Y',
        createBy: '1691994744527712257',
        creator: 'cyq',
        createTime: '2023-08-25 14:28:48',
        updateBy: '1691994744527712257',
        updator: 'cyq',
        updateTime: '2023-08-25 14:28:48',
        specFileName: 'CFCA数字证书服务协议_CFCA202308250000',
      }, // 未签署后端返回的数据
      isContract: false, // 电子债权凭证服务协议 为true显示
      loading: false,
      layerLoading: false,
      checked: false,
      showDialog: true,
      tableList: [
        // {"id":4,"entEnName":"init_supplier","entCnName":"初始化测试供应商","entCountry":"中国","entCode":"ENT2021042600004","archive":false,"auditStatus":"0","createBy":"xxx","createTime":"2021-04-27 15:14:53","updateBy":"xxx","updateTime":"2021-04-27 15:14:59","enable":true}
      ],
      agreementShow: false,
      pdfUrl: '',
      contractId: '',
      isOneComp: false,
    }
  },
  computed: {
    // showDialog(){
    //     return this.show
    // }
  },
  watch: {
    // show (val) {
    //   this.showDialog = val
    // }
  },
  methods: {
    closePdf() {
      this.agreementShow = false //协议显示
      this.pdfUrl = ''
    },
    //确认签署
    sureSign() {
      let _this = this
      if (this.layerLoading) return
      this.layerLoading = true
      _this.$http
        .post(`/kj-contract/contractInfo/signContract?id=${this.contractId}`)
        .then(
          function (data) {
            // _this.$router.push('/home');
            // _this.closePdf()
            _this.layerLoading = false
            // 签署成功
            _this.$message({
              message: _this.$t('common.signedSuccessfully'),
              type: 'success',
            })
            _this.chooseFinal(_this.currentComp)
          },
          function (response) {
            _this.layerLoading = false
          }
        )
    },
    //获取授权书
    async getAuthAgm() {
      let _this = this
      let result = await _this.$http
        .post(
          `/kj-idd/home/queryAuthAgreementSignStatus?enterpriseId=${this.currentComp.id}`
        )
        .then(
          function (data) {
            let url = data.data.data || false
            if (!url) {
              return false
            } else {
              return true
            }
          },
          function (response) {
            return true
          }
        )
      return result
    },

    //获取平台协议
    async getAgm() {
      let _this = this
      let result = await _this.$http
        .post(`/kj-contract/contractInfo/getPlatformServiceContractClient`)
        .then(
          function (data) {
            _this.loading = false
            // _this.isOneComp = false
            if (data.data.code == 200) {
              if (!data.data.data) {
                //如果没有返回时也就是不用签署
                //不需要签署
                return false
              }
              let signObj = data.data.data || {}
              let contractStatus = _this.filterJSONString(
                signObj.contractStatus
              )
              if (contractStatus == 'WAIT_SIGN') {
                //未签署
                //判断是否为买方 + 境内 - 2021-05-25去除需求
                // if(signObj.enterpriseRole == 0 && signObj.enterpriseLocation == 0){
                //     this.$alert('您如需签署平台服务协议，请联系运营人员协助处理，谢谢。', '提示', {
                //         confirmButtonText: '确定',
                //         callback: action => {
                //
                //         }
                //     });
                //     return true
                // }else {
                //     this.agreementShow = true
                //     this.pdfUrl = signObj.url
                //     // this.pdfUrl = '/static/sss.pdf'
                //     return true
                // }
                // _this.agreementShow = true
                // _this.pdfUrl = signObj.accessPath
                // _this.contractId = signObj.id
                // this.pdfUrl = '/static/sss.pdf'
                return false
              } else {
                //不需要签署
                return false
              }
            } else {
              return true
            }
          },
          function (response) {
            _this.loading = false
            return true
          }
        )
      return result
    },
    async close() {
      this.show = false
      // this.$emit('close')
      // this.$router.replace('/login')
      let res = await this.$sso.logout()
      if (res.code === '200') {
        localStorage.clear()
        sessionStorage.clear()
        this.$router.replace('/login')
      }
    },
    saveStatus(table) {
      this.$emit('sure', table)
    },

    //选择一个企业
    async chooseOne(table) {
      //判断是否有签署平台协议
      this.currentComp = table || {}

      this.chooseFinal(table)

      // ct 平台协议的逻辑 调试冲突并且跟产品沟通过 暂时注释
      // if (needSgin) {
      //   //需要签署可以进入系统
      //   //再判断是否授权书是否签署
      //   // let flagAuth = await this.getAuthAgm()
      //   // if(flagAuth){
      //   //     //已经签署可以进入系统
      //   //     this.chooseFinal(table)
      //   // }else {
      //   //     //否则去代办页面
      //   //
      //   // }
      // } else {
      //   //已经签署
      //   this.chooseFinal(table)
      // }

      // dyy 判断是否有签署电子债务凭证协议

      // let needSginDz = true
      //判断是否买家和境内企业

      // this.chooseFinal(table)

      //需要签署可以进入系统
      //再判断是否授权书是否签署
      // let flagAuth = await this.getAuthAgm()
      // if(flagAuth){
      //     //已经签署可以进入系统
      //     this.chooseFinal(table)
      // }else {
      //     //否则去代办页面

      // }
    },
    //最终选择企业进入
    async chooseFinal(table) {
      //判断该企业有无菜单，无菜单没有权限
      let curLoginName = sessionStorage.getItem('curLoginName')
      const powerFlag = await this.$API.enterprise.getMenuByEnterpriseId({
        sysChannel: 'kj_my',
        enterpriseId: table.id,
        loginName: curLoginName,
      })
      if (!powerFlag) return

      let _this = this
      let status = JSON.parse(table.status).dictParam // ENDURANCE  审核通过
      sessionStorage.setItem('enterpriseId', table.id) // 企业id
      sessionStorage.setItem('companyIdentCode', table.companyIdentCode)
      sessionStorage.setItem('companyName', table.companyName)
      sessionStorage.setItem('enterpriseRole', table.enterpriseRole)

      try {
        const data = await _this.$http.get(`/kj-web/sys/setOrgId?orgId=${table.id}`)
        //先获取企业的产品信息
        const queryObj = await _this.$http.get(`/kj-kyc/product/listByEnterpriseIdByClient`)
        let productTypeArr = queryObj.data.data || []
        _this.$store.commit('user/updateState', { key: 'listByEnterpriseId', value: productTypeArr })
        //国际结算特殊处理
        let calcCompany = productTypeArr.filter(item => {
          let productType = this.filterJSONString(item.productType)
          return productType == 'PURCHASE_PAYMENT'
        })
        if (calcCompany && calcCompany.length > 0) {
          //存在国际结算产品
          if (calcCompany.length == productTypeArr.length) {
            //有且只有一个国际计算产品
            sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', true)
          } else {
            sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', false)
          }
        } else {
          sessionStorage.setItem('IS_ONLY_PURCHASE_PAYMENT', false)
        }
        let productTypeObj = productTypeArr[0] || {}
        let productType = productTypeObj.productType ? JSON.parse(productTypeObj.productType).dictParam : ''
        sessionStorage.setItem('enterpriseProductType', productType)
        /* =========================================  ========================================= */
        var code = data.data.code

        if (code == '200') {
          // _this.$store.state.currentCompany = table
          if (status == 'ENDURANCE') {
            //审核通过 dyy
            // const query = this.$route.query
            this.$http.post(`/kj-contract/contractInfo/getDigipoServiceContractClient`).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  this.mediaId = res.data.data.mediaId
                  this.contractTypeDz = this.filterJSONString(res.data.data.contractType)
                  this.$router.push({ path: '/contract', query: {  mediaId: this.mediaId, contractTypeDz: this.contractTypeDz } })
                } else {
                  this.$router.push({ path: table.toPage || '/home' })
                  if (table.jumpFlag) {
                    this.$emit('getProductInfo')
                  }
                }
              } else {
                this.$message.error(res.data.message)
              }
            })
          } else {
            this.toOtherPage(table.id, status, productType)
          }
        } else {
          this.$alert(data.body.message, this.$t('login.tips'), { confirmButtonText: this.$t('login.confirm') })
        }
        _this.loading = false
        _this.agreementShow = false
        if (this.$parent.$options.name === 'home') {
          this.$parent.shopName = table.locationName
          if (table.jumpFlag) {
            // 激活二级菜单的主页
            sessionStorage.setItem('curMenuIndex', '395')
          } else {
            sessionStorage.setItem('curMenuIndex', '')
          }
          this.$parent.getMenu()
          //初始化
          this.$parent.iddSupFlag = false
          this.$parent.iddTimeShow = false
        }
      } catch (error) {
        _this.loading = false
        console.warn(error)
        // $.fn.mAlert('body',{tips:response});
        // _this.$alert(response, _this.$t('login.tips'), {
        //   confirmButtonText: _this.$t('login.confirm'),
        // })
      }
    },
    // 根据状态和产品跳转页面
    toOtherPage (id, status, productType) {
      if (status == 'INIT' && productType == 'E_FINANCE') {
        //待完善 产品类型为电商融资
        this.$router.push({ path: '/childEkycPortal/efinKyc', query: { id, status: status } })
      } else if (status == 'BACK' && productType == 'E_FINANCE') {
        //审核退回 产品类型为电商融资
        this.$router.push({ path: '/childEkycPortal/efinKyc', query: { id, status: status } })
      } else if (status == 'SUBMIT' && productType == 'E_FINANCE') {
        //建档审核中 产品类型为电商融资
        this.$router.push({ path: '/childEkycPortal/efinKyc', query: { id, status: status } })
      } else if (['INIT', 'BACK', 'SUBMIT'].includes(status) && productType == 'DIGIPO') {
        this.$router.push({ path: '/childEkycPortal/efinKyc', query: { id, status: status } })
      } else if (status == 'INIT' && productType == 'IDD') {
        //待完善 产品类型为IDD
        this.$router.push({ path: '/kycFileCN', query: { id, status: status } })
      } else if (status == 'BACK' && productType == 'IDD') {
        //审核退回 产品类型为IDD
        this.$router.push({ path: '/kycFileCN', query: { id, status: status } })
      } else if (status == 'SUBMIT' && productType == 'IDD') {
        //建档审核中 产品类型为IDD
        this.$router.push({ path: '/kycFileCN', query: { id, status: status } })
      } else {
        this.$alert( this.$t('login.onboardingStatusAbnormal'), this.$t('login.tips'), { confirmButtonText: this.$t('login.confirm') } )
      }
    },
    //获取企业
    getCompList(alredySelectComp = false) {
      this.loading = true
      if (alredySelectComp) {
        this.chooseOne(alredySelectComp)
        return
      }
      let _this = this
      // _this.$http.post('/kj-web/sys/getEnterpriseVO').then(
      _this.$http
        .post('/kj-sys/kjEnterprise/getEnterpriseByType', {
          productType: [
            'ASSET_BACKED_TOKENISATION',
            'DIGITAL_TRADE_TOKEN',
            'IDD',
            'DOC_EXCHANGE',
            'PURCHASE_PAYMENT',
            'E_FINANCE',
            'MARKET',
            'TRADE_FINANCE',
            'DIGIPO',
          ],
        })
        .then(
          function(data) {
            var code = data.data.code
            if (code == '200') {
              // _this.$router.push('/login');
              let tl = data.data.data || []
              // 内管端有且只有一个企业
              // tl.map(item=>{
              //     this.userName = item.entCnName || ''
              // })
              if (tl.length > 1) {
                _this.tableList = tl
              } else {
                if (tl.length == 1) {
                  _this.isOneComp = true
                  //只有一个企业
                  // _this.$router.push('/home')
                  _this.tableList = tl
                  //自动选择逻辑去除-------2022.08.01
                  // _this.chooseOne(tl[0])
                } else {
                  _this.$alert(
                    '您暂无企业，请联系运营人员协助处理，谢谢',
                    '提示',
                    {
                      confirmButtonText: '确定',
                      callback: action => {
                        _this.close()
                      },
                    }
                  )
                }
              }
            }

            _this.loading = false
          },
          function(response) {
            _this.loading = false
          }
        )
    },
  },
  mounted() {
    this.$nextTick(() => {
      let contentH = this.getSrcollHeight() * 0.6 - 100 - 90
      $('#box-content').height(contentH)
    })
    this.getCompList()
  },
}
</script>
